/* Dashboard.css */

/* Define styles for the header */

.header {
  background-color: #333;
  color: #fff;
  padding: 10px;
}

Box

/* Define styles for the button */
.download-button {
  background-color: #007bff;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 20px;
}

/* Define styles for stat boxes */
.stat-box {
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.bold-text {
  font-weight: bold;
}

@keyframes gradientBackground {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}



.headerStyle {
  border-color: grey;
  border-width: 3px;
  border-style: solid;
  /* Ensure border is visible */
  text-transform: uppercase;
  letter-spacing: 5px;
}





.client-background {
  background-image: url('../../../public/sparkling-bokeh-on-a-blue-background-illustration-2023-11-27-05-18-33-utc.jpg');
  display: flow-root;
  max-height: 100%;
  height: 100%;
  width: 100%;
  left: 0;
  animation: fadeI 0.5s ease-in-out;

  bottom: auto;
  z-index: -1;
}

@keyframes fadeI {
  0% {
    opacity: 0;
    transform: translateY(0px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.staff-background {
  background-image: url('../../../public/sparkling-bokeh-on-a-blue-background-illustration-2023-11-27-05-18-33-utc.jpg');
  display: flow-root;
  max-height: 100%;
  height: 100%;
  width: 100%;
  animation: fadeI 0.5s ease-in-out;
  left: 0;
  bottom: auto;
  z-index: -1;
}

.fac-background {
  background-image: url('../../../public/sparkling-bokeh-on-a-blue-background-illustration-2023-11-27-05-18-33-utc.jpg');
  display: flow-root;
  max-height: 100%;
  height: 100%;
  animation: fadeI 0.5s ease-in-out;

  width: 100%;
  left: 0;
  bottom: auto;
  z-index: -1;
}


.manage-background {
  background-image: url('../../../public/blurred-colorful-bokeh-background-2023-11-27-04-52-33-utc.jpg');
  display: flow-root;
  max-height: 100%;
  height: 100%;
  width: 100%;
  left: 0;
  bottom: auto;
  animation: fadeI 0.5s ease-in-out;

  z-index: -1;
}


.view-background {
  background-image: url('../../../public/ombre-blue-mosaic-background-illustration-2023-11-27-05-31-16-utc.jpg');
  display: flow-root;
  max-height: 100%;
  height: 100%;
  width: 100%;
  animation: fadeI 0.5s ease-in-out;

  left: 0;
  bottom: auto;
  z-index: -1;
}

.goal-background {
  background-image: url('../../../public/ombre-blue-mosaic-patterned-background-illustratio-2023-11-27-05-17-02-utc.jpg');
  display: flow-root;
  max-height: 100%;
  height: 100%;
  width: 100%;
  left: 0;
  bottom: auto;
  animation: fadeI 0.5s ease-in-out;

  z-index: -1;
}


.managegoal-background {
  background-image: url('../../../public/indigo-crystallized-patterned-background-2023-11-27-05-20-35-utc.jpg');
  display: flow-root;
  max-height: 100%;
  height: 100%;
  width: 100%;
  animation: fadeI 0.5s ease-in-out;

  left: 0;
  bottom: auto;
  z-index: -1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.animated-input {
  animation: fadeIn 2s ease-in-out;
}
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.3);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }
}

.heartbeat-icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  animation: heartbeat 2s infinite;
}


/* Define additional styles as needed */