/* GlobalStyles.css */
:root {
  --font-size-base: 16px; /* This sets the base font size for your app */
}

body {
  font-size: var(--font-size-base); /* Apply the base font size */
}
/* Other CSS files or within GlobalStyles.css */
.small-text {
  font-size: 0.75rem; /* Smaller text */
}

.normal-text {
  font-size: 1rem; /* Normal text */
}

.large-text {
  font-size: 1.25rem; /* Large text */
}

.extra-large-text {
  font-size: 1.5rem; /* Extra-large text */
}
