@keyframes color-change {
  0% {
    background: #0d98ba;
  }
  25% {
    background: #a2a2f4;
  }
  50% {
    background: #87cefa;
  }
  75% {
    background: #7f96d8;
  }
  100% {
    background: #ffffff;
  }
}

@keyframes drop-shadow {
  0% {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  50% {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}

.login-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 15px; /* More rounded corners */
  background-color: #F1EAFF;
  animation: color-change 10s infinite alternate, drop-shadow 10s infinite alternate;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 20px;
  
}

label {
  display: block;
  margin-bottom: 8px;
  color: #333;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px; /* Slightly more rounded */
  font-size: 16px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* subtle inner shadow for depth */
}

.login-btn,
.google-btn {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 8px; /* Slightly more rounded */
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.login-btn {
  background-color: #007bff;
  color: white;
  margin-bottom: 10px;
}

.login-btn:hover {
  background-color: #0056b3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.google-btn {
  background-color: white;
  color: #007bff;
  border: 1px solid #007bff;
}

.google-btn:hover {
  background-color: #f0f0f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
