/* Keyframe for sliding the left side towards the right */
@keyframes slideLeftToRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  50% {
    transform: translateX(100px); /* Move to the right */
    opacity: 1;
  }
  55% {
    opacity: 0; /* Start disappearing */
  }
  100% {
    transform: translateX(-100px); /* Reset to just outside the left */
    opacity: 0; /* Stay invisible */
  }
}

/* Keyframe for sliding the arrow from left to right */
@keyframes arrowMoveRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(50px); /* Move the arrow to the right */
    opacity: 0; /* Disappear as it reaches the right */
  }
}

/* Keyframe to reset the arrow back to the left after disappearing */
@keyframes arrowReset {
  0% {
    opacity: 0;
    transform: translateX(-50px); /* Start just offscreen to the left */
  }
  100% {
    opacity: 1;
    transform: translateX(0); /* Reappear on the left side */
  }
}

/* Loop the arrow animation to keep moving right recurrently */
@keyframes arrowLoop {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  70% {
    transform: translateX(50px); /* Move arrow to the right */
    opacity: 1;
  }
  75% {
    opacity: 0; /* Disappear */
  }
  100% {
    transform: translateX(-50px); /* Reset back to the left */
    opacity: 0;
  }
}

/* Apply the animation to the left side */
.slide-left {
  animation: slideLeftToRight 4s linear infinite; /* Recurrent without reverse */
}

/* Apply the recurrent right-moving animation to the arrow */
.slide-arrow {
  animation: arrowLoop 4s linear infinite; /* Keep the arrow moving right, disappearing, and reappearing */
}

/* Apply the animation to the right side (destination folder) */
.slide-right {
  animation: slideInRight 4s linear infinite; /* Right side stays in place */
}

/* Ensure a consistent look for the arrow */
.animated-arrow {
  font-size: 2rem;
}
